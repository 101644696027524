<template>
    <div>
        <div id="mainLoading" v-show="this.loading == true" class="darkBackground" style="text-align: center;">
            <div class="text-center" style="margin-top:25vh">
                <img width="250px"  :src="require('/public/redditWhiteLogoCropped1.png')">
                <!-- <v-progress-linear indeterminate color="1d9bf0" style="width: 150px; margin-left: auto; margin-right:auto; margin-top: 1.5rem;" height=3 background-opacity="0"></v-progress-linear> -->
            </div>
        </div>
        <div id="map">
            <mapbox
                access-token="pk.eyJ1IjoiYmlyZGh1bnQiLCJhIjoiY2t6YThnY2FrMDlncjJ3czZjNTZ5YzBpNSJ9.72hEYWjdoe8avgkItuQBug"
                :map-options="{
                    style: 'mapbox://styles/birdhunt/ckzf3vnph000414ny5b52cb18',
                    center: ['0', '7'],
                    zoom: 2,
                }"
                :attribution-control="{
                    show: true,
                    position:'bottom-right'
                }"
                :geocoder-control="{
                    show: true,
                    position: 'top-right'
                }"
                :fullscreen-control="{
                    show: true,
                    position: 'bottom-right'
                }"
                @map-init="initialiseMap"
                @map-load="loaded"
                @map-mouseenter:reddit-place-markers="mouseEntered"
                @map-mouseleave:reddit-place-markers="mouseLeft"
                @map-click="mapClicked"
                @map-click:reddit-place-markers="goToReddit"
            />
        </div>

    </div>
</template>

<script>

import Mapbox from "mapbox-gl-vue";
import PopupContent from "./PopupContent.vue";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

export default {

    props: {
        mapStyle: String,
        disUnit: String,
    },
    
    name: 'mapRedditHunt',

    components:{
        Mapbox
    },

    data(){
        return {
            loading: true,
            marker: null,
            popup: null,
            dialog: false,
            dbl: false,
            clicked: false,
            errorMessage: false,
            redditPlaces: [],
            redditPlacesJson: [],
            map: null,
        }
    },

    mounted(){
        // load redditplaces from csv
        this.loadRedditPlaces();

    },

    methods: {
        loadRedditPlaces(){
            let csvFilePath = '/reddit_data.csv';
            let request = new XMLHttpRequest();
            request.open("GET", csvFilePath, false);
            request.send(null);
            let jsonObject = this.csvJSON(request.responseText);
            this.redditPlacesJson = jsonObject;
            this.redditPlaces = this.convertToGeoJson(jsonObject);
        },
        csvJSON(csv){
            var lines = csv.split("\n");
            var result = [];
            var headers = lines[0].split(",");
            // for each header get rid of '\r'
            for (let i = 0; i < headers.length; i++) {
                headers[i] = headers[i].replace('\r', '');
            }
            for(var i = 1; i < lines.length; i++){
                var obj = {};
                var currentline = lines[i].split(",");
                for(var j = 0; j < headers.length; j++){
                    obj[headers[j]] = currentline[j];
                }
                result.push(obj);
            }
            return result;
        },
        convertToGeoJson(data){
            let features = [];
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                let feature = {
                    "type": "Feature",
                    "properties": {
                        "Subreddit": item.Subreddit,
                        "id": item.Region + "_" + item.Subreddit,
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [parseFloat(item.Longitude), parseFloat(item.Latitude)]
                    }
                }
                features.push(feature);
            }
            return features;
        },
        loaded: function(map) {
            this.loading = false;
            map.on('idle',function(){
                map.resize()
            })

            let img = new Image();
            img.onload = function() {
                map.addImage('rh-marker', img);
            };
            img.src = '/redditPin.png';

            let img2 = new Image();
            img2.onload = function() {
                map.addImage('rh-marker-cluster', img2);
            };
            img2.src = '/redditCluster.png';

            
            // add markers to map if not already added
            this.addMarkersToMap();

        },
        initialiseMap: function(map) {
            map._isVue = true;
            this.map = map;
            // const geocoder = new MapboxGeocoder({
            //     accessToken: "pk.eyJ1IjoiYmlyZGh1bnQiLCJhIjoiY2t6YThnY2FrMDlncjJ3czZjNTZ5YzBpNSJ9.72hEYWjdoe8avgkItuQBug",
            // })

            // map.addControl(
            //     geocoder
            // );
        },
        
        addMarkersToMap: function(){

            if(this.map.getSource('reddit-marker-places')){
                console.log('source already exists');
                var data = {
                    type: "FeatureCollection",
                    features: this.redditPlaces
                };
                this.map.getSource('reddit-marker-places').setData(data);
            } else {
                console.log('source does not exist');
                this.map.addSource('reddit-marker-places', {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: this.redditPlaces
                    },
                    cluster: false,
                });
            }

            if(!this.map.getLayer(`reddit-place-markers`)){
                this.map.addLayer({
                    id: `reddit-place-markers`,
                    type: "symbol",
                    source: 'reddit-marker-places',
                    filter: ['!=', 'cluster', true],
                    layout: {
                        "icon-image": "rh-marker",
                        "icon-size": 0.12,
                        "icon-allow-overlap": true,
                        "icon-ignore-placement": true,
                    }
                });
            }

            // cluster layer
            // if(!this.map.getLayer(`reddit-place-cluster`)){
            //     this.map.addLayer({
            //         id: `reddit-place-cluster`,
            //         type: "symbol",
            //         source: 'reddit-marker-places',
            //         filter: ['==', 'cluster', true],
            //         layout: {
            //             "icon-image": "rh-marker-cluster",
            //             "icon-size": 0.125,
            //             "icon-allow-overlap": true,
            //             "icon-ignore-placement": true,
            //         }
            //     });
            // }

        },

        goToReddit(map, e){
            let url = 'https://www.reddit.com/r/' + e.features[0].properties.Subreddit;
            window.open(url, '_blank');
        },

        mouseEntered(map, e) {
            if(this.popup){
                this.popup.remove();
                this.popup = null;
            }
            map.getCanvas().style.cursor = 'pointer'
            var coordinates = e.features[0].geometry.coordinates.slice();
            //var description = e.features[0].properties.description;
            //var postcode = e.features[0].properties.postcode;

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] +=
                e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            this.popup = new mapboxgl.Popup({ offset: [0, -30], className: "popupStyle", maxWidth: '300px', closeButton: false }).setLngLat(coordinates).setHTML('<div id="vue-popup-content"></div>').addTo(map);
            new PopupContent({
                        propsData: { feature: e},
                    }).$mount('#vue-popup-content')
            this.popup._update()
        },
        mouseLeft() {
            this.map.getCanvas().style.cursor = '';
            if(this.popup){
                // sleep for 3 seconds
                setTimeout(() => {
                    this.popup.remove();
                    this.popup = null;
                }, 1500);
            }
        },
        mapClicked(){
            if(this.popup){
                this.popup.remove();
                this.popup = null;
            }
        },

    },
}
</script>

<style>
#mainLoading { z-index: 4; position: absolute; width: 100%;  height: 100% !important; overflow-y: hidden !important;}
#map { z-index: 0 !important; position: absolute !important; width: 100% !important; height: 100% !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}

.background{
    background: #1d9bf0;
}

.darkBackground{
    background: #0D1117;
}

.marker {
  background-image: url('/public/redditPin.png');
  background-size: contain;
  width: 30px;
    height: 80px;
  cursor: pointer;
}
.customDialog.v-dialog--fullscreen {
    width: 41.66%;
    right: 0px;
    left: unset;
    border-radius: 0px;
}
.buttonSpacer {
    margin-top: 20vh;
}
#submissionBtn{z-index: 3 !important; position: absolute !important; width: 20% !important; margin-top: 10px; margin-left: 10px; max-width: 300px; min-width: 240px;}
#alert{z-index: 3 !important; position: absolute !important; margin-bottom: 30px; width: calc(100% - 580px); margin-top:10px; margin-right: calc(10px + 240px + 10px); margin-left: calc(310px + 10px); height: 36px;}

@media (min-width: 767px) and (max-width: 1140px) {
    #alert{z-index: 3 !important; position: absolute !important; margin-bottom: 30px; width: calc(100% - 550px); margin-top:10px; margin-right: calc(10px + 240px + 10px); margin-left: calc(280px + 10px); height: 36px;}
}

@media (max-width: 767px) {
        .visible-mobile{
            display: inline;
        }
        .hidden-mobile {
          display: none;
        }
        #submissionBtn{z-index: 3 !important; position: fixed !important; bottom: 0px; width: 100vw !important; margin-bottom: 10px; margin-left: 10px; max-width: calc(100vw - 20px);}
        .customDialog.v-dialog--fullscreen {
            width: 100%;
            right: 0px;
            left: 0px;
            border-radius: 0px;
        }
        #map{ z-index: 0 !important; position: fixed !important; width: 100vw !important; height: 100vh !important; border-width: unset !important; border-style: unset !important; border-color: unset !important; overflow-y: hidden !important;}
        #alert{z-index: 3 !important; position: fixed !important; bottom: 30px; width: 100vw !important; max-width: calc(100vw - 20px); margin-top:unset; margin-right: 10px; margin-left: 10px; height: unset;}
        .noScroll{
            position: fixed;
        }

      }
@media (min-width: 767px) {
    .visible-mobile{
        display: none;
    }
    
}

</style>