var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.loading == true),expression:"this.loading == true"}],staticClass:"darkBackground",staticStyle:{"text-align":"center"},attrs:{"id":"mainLoading"}},[_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"25vh"}},[_c('img',{attrs:{"width":"250px","src":require('/public/redditWhiteLogoCropped1.png')}})])]),_c('div',{attrs:{"id":"map"}},[_c('mapbox',{attrs:{"access-token":"pk.eyJ1IjoiYmlyZGh1bnQiLCJhIjoiY2t6YThnY2FrMDlncjJ3czZjNTZ5YzBpNSJ9.72hEYWjdoe8avgkItuQBug","map-options":{
                style: 'mapbox://styles/birdhunt/ckzf3vnph000414ny5b52cb18',
                center: ['0', '7'],
                zoom: 2,
            },"attribution-control":{
                show: true,
                position:'bottom-right'
            },"geocoder-control":{
                show: true,
                position: 'top-right'
            },"fullscreen-control":{
                show: true,
                position: 'bottom-right'
            }},on:{"map-init":_vm.initialiseMap,"map-load":_vm.loaded,"map-mouseenter:reddit-place-markers":_vm.mouseEntered,"map-mouseleave:reddit-place-markers":_vm.mouseLeft,"map-click":_vm.mapClicked,"map-click:reddit-place-markers":_vm.goToReddit}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }