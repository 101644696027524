<template>
    <div style="padding: 12px;">
    <div style="text-align: center; font-weight: 500; font-size: 14px;">
        <strong>r/{{feature.features[0].properties.Subreddit}}</strong>
        <br>
        <div style="padding-top: 0.5rem;">
          <!-- go to subreddit -->
          <span target="_blank" @click="openReddit" style="cursor: pointer; color: #ff4500; text-decoration: underline;">
            Go to Subreddit
          </span>
        </div>
    </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  props: {
    feature: {
      required: true,
      type: Object,
    }
  },
  methods: {
    viewClicked() {
        let route = "/location/show/{id}";
        var newRoute = route.replace("{id}", this.feature.id);
        this.$router.push(newRoute);
    },
    readableDate(date){
      return window.Utils.dateTimeToDDMMYYYY(date);
    },
    openReddit(){
            let url = 'https://www.reddit.com/r/' + this.feature.features[0].properties.Subreddit;
            window.open(url, '_blank');
    },
    openInstagram(){
            let url = 'https://www.instagram.com/explore/locations/' + this.feature.features[0].properties.id;
            window.open(url, '_blank');
    },
    openFacebook(){
            let url = 'https://www.facebook.com/' + this.feature.features[0].properties.id;
            window.open(url, '_blank');
    },
    
    

  }
})
</script>

<style>

.mapboxgl-popup-content {
  background-color: #0D1117 !important;
  color: #CCDAE7 !important;
}

.mapboxgl-popup-tip {
  border-bottom-color: #0D1117 !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #0D1117 !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #0D1117 !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #0D1117 !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #0D1117 !important;
}
</style>