<template>
  <v-app>
    <v-app-bar
      app
      color="#0D1218"
      dark
      elevation=0
      style="border-bottom: 2px solid #fd4400 !important;"
    >
      <div class="d-flex align-center">
        <v-img
          alt="RedditHunt Logo"
          contain
          :src="require('/public/Logo2.png')"
          transition="scale-transition"
          width="150"
          dense
        />
        <a href="https://www.huntintel.io/" style="color: #44d62c; text-decoration: underline; margin-left: 1rem;" class="hidden-mobile">
          <img
            alt="Hunt Intelligence Logo"
            :src="require('/public/HuntLogoClean.png')"
            style="max-width: 65px !important; padding-bottom: 0.36rem;"
          />
        </a>

        <!-- <a href="https://www.huntintel.io/" style="color: #44d62c; text-decoration: underline;"> by Hunt Intelligence</a> -->
      </div>

      <v-spacer></v-spacer>
<!-- 
      <v-btn
        text
        color="#1d9bf0"
        dark
        icon
        @click="help = true"
        style="text-transform: none; letter-spacing: normal; font-weight: 600;"
      >
        <v-icon style="font-size: 24px;">mdi-cog</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <map-bird-hunt @styleChanged="help=false" :mapStyle="mapStyle" :disUnit="disUnit"/>
            <v-overlay
                opacity="0.66"
                color="#000000"
                :value="help"
            ></v-overlay>

            <v-dialog class="advertDialog rounded-4" v-model="huntDialog" max-width="750px">
              <v-row no-gutters>
                <!-- Left side: Image -->
                <v-col cols="5" style="background-color: #0D1117">
                  <div class="w-100 h-100" style="background-image: url('/mapBackground.jpeg'); background-size: cover; background-position: center; position: relative; top: 0; left: 0; z-index: 0; ">
                    <!-- div wih gradient overlay -->
                    <div class="w-100 h-100" style="background: linear-gradient(120deg, rgba(13, 17, 23, 0.80) 0%, rgba(13, 17, 23, 0.99) 100%); position: absolute; top: 0; left: 0; z-index: 1;">
                                        <!-- logo in top right corner -->
                                        <img
                              alt="Hunt Intelligence Logo"
                              :src="require('/public/huntintel.png')"
                              style="max-width: 75px !important; position: relative !important; top: 24px; left: 24px; z-index: 1000;"
                            />
                    </div>

                  </div>
                    <!-- logo in top right corner -->
                    <!-- <v-img
                      alt="Hunt Intelligence Logo"
                      cover
                      :src="require('/public/mapBackground.jpeg')"
                      transition="scale-transition"
                      dense
                      style="min-height: 100%; min-width: 100%; opacity: 0.3;"
                    /> -->
                </v-col>
                
                <!-- Right side: Content -->
                <v-col cols="7">
                  <v-card class="advertDialog p-3 h-100 rounded-0">
                    <v-card-title class="advertDialog rounded-0 p-0">
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeDialog">
                    <v-icon color="#8A97A3">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                    <v-card-text>
                      <div class="headline-title">{{ title }}</div>
                      <div class="subtext">{{ subtext }}</div>
                      <v-btn color="#CCDAE7" class="elevation-0 mt-3 w-100" @click="redirectToSite"><span style="text-decoration: none; text-transform: none; letter-spacing: normal; font-weight: 600;">{{ buttonText }}</span></v-btn>
                      <div class="no-thank-you-container">
                        <div class="no-thank-you mt-1">Or <br><span @click="closeDialog" style="font-weight: bold; cursor: pointer;">No thank you, I'm happy with RedditHunt</span></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-dialog>
    </v-main>
  </v-app>
</template>

<script>

import mapBirdHunt from './components/map.vue';

export default {
  name: 'App',

  components: {
    mapBirdHunt
  },

  mounted(){
    // show advert dialog for first time users
    if(localStorage.getItem('firstTime') === null){
      // only show on desktop
      if(window.innerWidth > 767){  
        this.huntDialog = true;
        // localStorage.setItem('firstTime', 'false');
      }

    }
  },

  methods: {
    closeDialog(){
      this.huntDialog = false;
    },
    redirectToSite(){
      window.open('https://huntintel.io/', '_blank');
    }
  },

  data(){
    return{
      help: false,
      mapStyle: 'satellite',
      disUnit: 'kilometers',
      huntDialog: false,
      title: 'Easily find social media posts surrounding a location',
      imageUrl: require('/public/Logo2.png'),
      subtext: 'Love RedditHunt? Get access to more features by signing up for a free trial of Hunt Intelligence. Search millions of social media posts from around the world. Get a 7 day free trial now. No credit card required.',
      buttonText: 'Start Free Trial'
    
  }},
};
</script>

<style>

.customDialog.v-dialog--fullscreen {
    width: 41.66%;
    right: 0px;
    left: unset;
    border-radius: 0px;
}
.advertDialog {
  background-color: #0D1117 !important;
  color: #CCDAE7 !important;
}
.headline-title {
  font-weight: 800 !important;
  margin-top: 10px;
  margin-bottom: 2rem;
  font-size: 2rem !important;
  line-height: 2rem !important;
  text-align: center !important;
  /* color as a gradient */
  background: linear-gradient(120deg, #CCDAE7 0%, #8a97a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}
.subtext {
  margin-top: 10px;
  color: #8a97a3;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
}
.no-thank-you {
  margin-top: 10px;
  font-size: 12px;
  color: #8a97a3;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.no-thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
        .padding-mobile {
          padding-left: 0px;
          padding-right: 0px;
        }
        .customDialog.v-dialog--fullscreen {
            width: 100%;
            right: 0px;
            left: 0px;
            border-radius: 0px;
        }
        .noScroll{
            position: fixed;
        }
      }

</style>
